
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Ordinal' },
      { field: 'Published' },
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '類別',
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: { sheetName: '最新消息類別清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '最新消息類別清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        {
          field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true,
          editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
          filters: [{ checked: false, label: '包含' }],
          slots: { filter: 'filter-name' }
        },
        { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, align: "right", width: 100, sortable: true },
        {
          field: "Published", title: "已發佈", showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true, resizable: false,
          cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } }
        },
      ],
      promises: {
        query: model ? (params) => {
          if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          else params.condition = new Condition('ParentId', Operator.IsNull)
          return model.dispatch('newsCategory/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('newsCategory/query') : undefined,
        save: model ? (params) => model.dispatch('newsCategory/save', params) : undefined
      },
      modalConfig: { showFooter: true },
      treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: 'right',
      items: [
        {
          field: 'Name', title: '名稱', span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'ParentId', title: '父類別', span: 24,
        //   slots: { default: 'column-parent-id' }
        // },
        {
          field: 'Published', title: '已發佈', span: 12,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'Ordinal', title: '排序', span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
      ],
      rules: {
        Name: [{ type: 'string', required: true }]
      }
    }

    const parentIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇分類',
      textField: 'Name',
      valueField: 'Id',
      formatText: async (row: any) => {
        let entity = await model!.dispatch("newsCategory/find", row.Id); // eslint-disable-line
        let text = entity.Name
        while (entity.Parent) {
          entity = entity.Parent
          text = `${entity.Name}>${text}`
        }
        return text
      },
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("newsCategory/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          else params.condition = new Condition('ParentId', Operator.IsNull)
          return model!.dispatch("newsCategory/query", params); // eslint-disable-line
        }
      },
      treeConfig: { parent: 'ParentId', children: 'Children' }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      parentIdSelectOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
})
